import { Component } from "@angular/core";
import { AuthenticationService } from "./pages/login/login.services";
import { Router } from '@angular/router';
import { MatPaginatorIntl} from '@angular/material/paginator';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent extends MatPaginatorIntl {
  title = "black-dashboard-angular";

  itemsPerPageLabel = 'Registros por página';//customize item per page label

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ){
    super();
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('white-content');
  }

  openDialog(): void {
    const modal = document.getElementById('myModal');
    modal.style.display = 'flex';
  }

  closeDialog(): void {
    const modal = document.getElementById('myModal');
    modal.style.display = 'none';
  }

  logout() {
    this.authenticationService.logout();
  }

  sessionExpire(): void {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
    const myModal: HTMLElement = <HTMLElement>document.getElementById('myModal');
    myModal.style.display = 'none';
  }

  refreshSession(): void {
    this.authenticationService.refresToken().subscribe(
      (data: any) => {
        console.log(data);
        localStorage.setItem('currentUser', JSON.stringify(data))
        localStorage.setItem('auth', data.token);
        localStorage.setItem('refrest_tk', data.refresh_token);
        const myModal: HTMLElement = <HTMLElement>document.getElementById('myModal');
        myModal.style.display = 'none';
      },
      (error) => {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
        const myModal: HTMLElement = <HTMLElement>document.getElementById('myModal');
        myModal.style.display = 'none';
      }
    )
  };
}
