import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthenticationService } from './../../pages/login/login.services';
import { timerSession } from './sessionexpire';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            console.log(this.router.url)
            if (err.status === 401 && this.router.url != '/login' && this.router.url != '/forgot' && this.router.url != '/change') {
                const myModal: HTMLElement = <HTMLElement>document.getElementById('myModal');
                const auth = localStorage.getItem("auth");
                myModal.style.display = 'flex';
                timerSession(this.router, this.authenticationService, auth);
            }
            const error = {
                ...err.error,
                status: err.status,
            };
            return throwError(error);
        }))
    }
}