export const timerSession = (router: any, logout: any, auth: string) => {
  setTimeout(() => {
    const auth2 = localStorage.getItem("auth");
    if (auth2 == auth) {
      logout.logout();
      router.navigate(['/login']);
      const myModal: HTMLElement = <HTMLElement>document.getElementById('myModal');
      myModal.style.display = 'none';
    }
  }, 15000);
}