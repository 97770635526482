export const initiateFirstTimer = (status: string) => {
  
  console.log(status);
  switch (status) {
    case 'INITIATE_TIMER':
      break;

    case 'RESET_TIMER':
      break;

    case 'STOPPED_TIMER':
      showSendTimerDialog();
      break;

    default:
      break;
  }
}

export const initiateSecondTimer = (status: string) => {
  console.log(status);
  switch (status) {
    case 'INITIATE_SECOND_TIMER':
      break;

    case 'SECOND_TIMER_STARTED':
      break;

    case 'SECOND_TIMER_STOPPED':
      showSendTimerDialog();
      break;

    default:
      break;
  }
}

const showSendTimerDialog = (): void => {
  const modal = document.getElementById('myModal');
  modal.style.display = 'block';
}