import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { AuthenticationService } from "../login/login.services";
import { ChangeService } from "./changepwd.service";

@Component({
  selector: "changepwd",
  templateUrl: "changepwd.component.html",
  styleUrls: ["changepwd.component.scss"],
})
export class ChangepwdComponent implements OnInit {
  changeForm: UntypedFormGroup;
  error: string = '';
  has8Chars: boolean = false;
  hasMayus: boolean = false;
  hasMinus: boolean = false;
  hasNumber: boolean = false;
  hasSpecial: boolean = false;
  showPwd: boolean = false;

  correctPwd: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeService: ChangeService,
    private router: Router,
    private authservices: AuthenticationService,
  ) {
  }

  ngOnInit() {
    this.changeForm = this.formBuilder.group({
      userPass: ['', [Validators.required, Validators.minLength(8)]],
      userRePass: ['', [Validators.required, Validators.minLength(8)]],
      expirationTime: ['', [Validators.required]],
    });
  }

  // Funcion para limpiar las validaciones de la pwd
  validatePwd(): void{
    let values = this.changeForm.value.userPass;

    // Regex
    var regExpMayus = /[A-Z]/g;
    var regExpMinus = /[a-z]/g;
    var regExpNumber = /[0-9]/g;
    var regExpSpecial = /\W+/g;

    if(regExpSpecial.test(values)){
      this.hasSpecial = true;
    }else{
      this.hasSpecial = false;
    }

    if(values.length >= 8){
      this.has8Chars = true;
    }else{
      this.has8Chars = false;
    }

    if(regExpMayus.test(values)){
      this.hasMayus = true;
    }else{
      this.hasMayus = false
    }

    if(regExpMinus.test(values)){
      this.hasMinus = true;
    }else{
      this.hasMinus = false
    }

    if(regExpNumber.test(values)){
      this.hasNumber = true;
    }else{
      this.hasNumber = false
    }

    // Pwd correcta?
    if(this.hasNumber && this.hasMayus && this.has8Chars && this.hasMinus && this.hasSpecial){
      this.correctPwd = true;
    }else{
      this.correctPwd = false;
    }

  }

  onSubmit(): void {
    const values = this.changeForm.value;
    const params = {
      password: values.userPass,
      re_password: values.userRePass,
      expiry_time: values.expirationTime,
    }
    this.changeService.changePwd(params).subscribe(
      data => {
        this.authservices.logout();
        this.router.navigate(["/login"]);
      },
      rsp => {
        switch(rsp.status) {
          default:
            this.setError(rsp.message);
        }
      });
  }

  setError(msg: string): void {
    this.error = msg;
    setTimeout(() => {
      this.error = "";
    }, 4000)
  }
}
