import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Constants } from './../../components/config/Endpoints';
import { AuthModel } from './Auth'
import { NgIdleService } from 'src/app/components/config/idle.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<AuthModel>;
    public currentUser: Observable<AuthModel>;

    constructor(private http: HttpClient, private ngIdle: NgIdleService,) {
        this.currentUserSubject = new BehaviorSubject<AuthModel>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): AuthModel {
        return this.currentUserSubject.value;
    }

    login(username: string, password: string, captcha: string, ip: string) {
        return this.http.post<any>(`${Constants.loginUrl}`, { username, password, captcha, ip }, {observe: 'response'} )
            .pipe(map(resp => {
                if (resp.body['message'] == "success") {
                    const u = resp.body['data']
                    return u;
                }
            }));
    }

    logout() {
      this.logoutService().subscribe(
        () => {
          localStorage.removeItem('currentUser');
          localStorage.removeItem('auth');
          localStorage.removeItem('refrest_tk');
          this.currentUserSubject.next(null);
        },
        (error) => {
          console.log('error');
          localStorage.removeItem('currentUser');
          localStorage.removeItem('auth');
          localStorage.removeItem('refrest_tk');
          this.currentUserSubject.next(null);
        }
      )
    }

    private logoutService() {
      let headers = new HttpHeaders();
      const auth = localStorage.getItem('auth');
      headers = headers.set('Authorization', 'Bearer '+auth);
      return this.http.post<any>(`${Constants.logout}`, {}, {headers:headers});
    }

    validate2FA(data: any) {
        return this.http.post<any>(`${Constants.login2FAUrl}`, data, {observe: 'response'})
        .pipe(map(resp => {
            if (resp.body['message'] == "success") {
                const u = resp.body['data']
                localStorage.setItem('currentUser', JSON.stringify(u))
                localStorage.setItem('auth', u.token);
                localStorage.setItem('refrest_tk', u.refresh_token);
                this.currentUserSubject.next(u);
                return u;
            }
        }));
    }

    refresToken() {
      return this.http.post<any>(`${Constants.refreshToken}/${localStorage.getItem('refrest_tk')}`, {}, {observe: 'response'})
      .pipe(map(resp => {
        if (resp.body['message'] == "success") {
            const u = resp.body['data']
            localStorage.setItem('currentUser', JSON.stringify(u))
            localStorage.setItem('auth', u.token);
            localStorage.setItem('refrest_tk', u.refresh_token);
            this.currentUserSubject.next(u);
            return u;
        }
      }));
    }
}
