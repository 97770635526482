import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Constants } from '../../components/config/Endpoints';
import { ForgotModel } from '../login/Auth';

@Injectable({ providedIn: 'root' })
export class ForgotpwdService {
    
    constructor(private http: HttpClient) {}

    forgotpwd(forgotPwd: ForgotModel): Observable<ForgotModel>{
      let headers = new HttpHeaders();

      return this.http.post<ForgotModel>(`${Constants.forgotUrl}`, forgotPwd, {headers:headers})
      .pipe(tap(data => {
          if(data['message'] == 'success' ){
            return data['data']
          }else{
            // return 
          }
      }));
    }

}